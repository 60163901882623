@font-face {
font-family: '__GilroyFontDark_b1701b';
src: url(/_next/static/media/73fdfd3d28dae0f0-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__GilroyFontDark_Fallback_b1701b';src: local("Arial");ascent-override: 72.93%;descent-override: 21.42%;line-gap-override: 24.72%;size-adjust: 105.99%
}.__className_b1701b {font-family: '__GilroyFontDark_b1701b', '__GilroyFontDark_Fallback_b1701b';font-weight: 400
}.__variable_b1701b {--font-gilroy: '__GilroyFontDark_b1701b', '__GilroyFontDark_Fallback_b1701b'
}

@font-face {
font-family: '__GilroyFontLight_dcb7ca';
src: url(/_next/static/media/495fbfd4658f644d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
}

@font-face {
font-family: '__GilroyFontLight_dcb7ca';
src: url(/_next/static/media/495fbfd4658f644d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
}@font-face {font-family: '__GilroyFontLight_Fallback_dcb7ca';src: local("Arial");ascent-override: 73.46%;descent-override: 21.20%;line-gap-override: 18.93%;size-adjust: 105.64%
}.__className_dcb7ca {font-family: '__GilroyFontLight_dcb7ca', '__GilroyFontLight_Fallback_dcb7ca'
}.__variable_dcb7ca {--font-gilroy: '__GilroyFontLight_dcb7ca', '__GilroyFontLight_Fallback_dcb7ca'
}

.cta_sticky-phone-button__L1vYv {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000;
  width: 0px;
}

.cta_sticky-phone-button__L1vYv > a:first-child {
  color: white;
  width: 100%;
}

.cta_sticky-phone-button__L1vYv > a:first-child p:first-child {
  color: white;
  white-space: nowrap;
  height: 100%;
  width: 100vw;
  border-radius: 0px 0px 5px 5px;
  background: linear-gradient(92deg, #aa789f 1.57%, #4c3472 63.77%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 20px 10px 20px;
  font-size: 20px;
  letter-spacing: 2px;
  text-align: center;
}

.cta_sticky-phone-button2__tirGu {
  
  left: 0px;
  top: 0px;
  z-index: 1000;
  width: 0px;
}

.cta_sticky-phone-button2__tirGu > a:first-child {
  color: white;
  width: 100%;
}

.cta_sticky-phone-button2__tirGu > a:first-child p:first-child {
  color: white;
  white-space: nowrap;
  height: 100%;
  width: 100vw;
  border-radius: 0px 0px 5px 5px;
  background: linear-gradient(92deg, #aa789f 1.57%, #4c3472 63.77%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 20px 10px 20px;
  font-size: 20px;
  letter-spacing: 2px;
  text-align: center;
}

.header_main_services_TransformBrand__45ZTQ {
  display: none;
}

.header_main_container___PSOX {
  display: none;
}

@media screen and (min-width: 815px) {
  .header_main_container___PSOX {
    display: block;
    /* max-height: 96px;
      min-height: 96px; */

    display: flex;
    justify-content: space-between;
  }
}

.header_main__UkhMP {
  min-width: 100%;
  display: flex;
  align-items: center;
  z-index: 10;
}

.header_main_scrolled__YGQMs {
  background: #ffffff;
  position: fixed;
  top: 0;
  height: clamp(5rem, 10vh, 8rem);
  z-index: 1000;
  box-shadow:
    0px 0px 20px 5px rgba(0, 0, 0, 0.1),
    0px 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset 0px 0px 4px 0px rgb(255, 255, 255);
}

.header_main_scrolled_before__dp367 {
  background-color: #fcfcfc;
  z-index: 1000;
  height: clamp(5rem, 10vh, 8rem);
  /* max-height: 10vh; */
  box-shadow:
    0px 0px 20px 5px rgba(0, 0, 0, 0.1),
    0px 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset 0px 0px 4px 0px rgb(255, 255, 255);
}

.header_header_container__cI0ko {
  display: flex;
  min-width: auto;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 950px) {
  .header_header_container__cI0ko {
    display: flex;
    min-width: 100vw;
    justify-content: space-between;
    align-items: center;
  }
}

.header_logo_container_after__zr2sk {
  width: clamp(5rem, 20vw, 20rem);
  height: clamp(30px, 5vw, 70px);
}

.header_container_one__PQ_xb {
  display: none;
}

@media screen and (min-width: 951px) {
  .header_container_one__PQ_xb {
    display: block;
    max-width: 30%;
    min-width: auto;
    margin-left: clamp(10px, 1.5vw, 20px);
  }
}

.header_logo_container_before__zrUsG {
  /* margin-left: -5%; */
  /* margin-top: -3.7vh;
      margin-bottom: -3.7vh; */
  /* min-height: 12.8vh;
      max-height: 12.8vh; */
  /* min-width: 5rem;
      max-width: 15rem; */

  width: clamp(5rem, 20vw, 20rem);
  /* 
      padding: 1vw; */
}

/* nav css */

.header_container_second__o4cYq {
  z-index: 1000;
  position: relative;
}

.header_nav_container__uEV9J {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 1vh;
}

.header_whatsap_button__3I1wA {
  display: none;
}

.header_getStartedModel__uAiP5 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

@media screen and (max-width: 982px) {
  .header_getStartedModel__uAiP5 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}

@media screen and (min-width: 1067px) {
  .header_whatsap_button__3I1wA {
    position: relative;
    display: block;
  }
}

.header_nav_container_before__vSoxB {
  align-items: center;
  box-shadow:
    0px 0px 20px 5px rgba(0, 0, 0, 0.1),
    0px 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset 0px 0px 4px 0px rgb(255, 255, 255);
  box-shadow: 0 0 0 2px slate-500;
  background: linear-gradient(180deg, #4e3572 0%, #733c64 100%);
  color: #fcfcfcfc;
}

.header_call_to_action_buttons__IjSwb {
  align-items: center;
  box-shadow:
    0px 0px 20px 5px rgba(0, 0, 0, 0.1),
    0px 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset 0px 0px 4px 0px rgb(255, 255, 255);
  box-shadow: 0 0 0 2px slate-500;
  background: linear-gradient(180deg, #4e3572 0%, #733c64 100%);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 60px;
  color: #fcfcfcfc;
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin: 0px 5px;
  font-size: 0.8rem;
}

/* third container */

.header_container_third__pcFpJ {
  display: none;
}

@media screen and (min-width: 950px) {
  .header_container_third__pcFpJ {
    display: block;
    min-width: 2%;
    max-width: 25%;
    padding-right: 1vw;
    cursor: pointer;
    max-height: 41px;
  }
}

@media screen and (max-width: 950px) {
  .header_container_one__PQ_xb {
    display: block;
    max-width: auto;
    min-width: auto;
  }

  .header_container_second__o4cYq {
    min-width: auto;
    max-width: auto;
  }
}


.sticky-phone-button {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000;
  width: 0px;
}

.sticky-phone-button > a:first-child {
  color: white;
  width: 100%;
}

.sticky-phone-button > a:first-child p:first-child {
  color: white;
  white-space: nowrap;
  height: 100%;
  width: 100vw;
  border-radius: 0px 0px 5px 5px;
  background: linear-gradient(92deg, #aa789f 1.57%, #4c3472 63.77%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 20px 10px 20px;
  font-size: 20px;
  letter-spacing: 2px;
  text-align: center;
}

.sticky-phone-button2 {
  
  left: 0px;
  top: 0px;
  z-index: 1000;
  width: 0px;
}

.sticky-phone-button2 > a:first-child {
  color: white;
  width: 100%;
}

.sticky-phone-button2 > a:first-child p:first-child {
  color: white;
  white-space: nowrap;
  height: 100%;
  width: 100vw;
  border-radius: 0px 0px 5px 5px;
  background: linear-gradient(92deg, #aa789f 1.57%, #4c3472 63.77%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 20px 10px 20px;
  font-size: 20px;
  letter-spacing: 2px;
  text-align: center;
}

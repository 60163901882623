.main_services_TransformBrand {
  display: none;
}

.main_container {
  display: none;
}

@media screen and (min-width: 815px) {
  .main_container {
    display: block;
    /* max-height: 96px;
      min-height: 96px; */

    display: flex;
    justify-content: space-between;
  }
}

.main {
  min-width: 100%;
  display: flex;
  align-items: center;
  z-index: 10;
}

.main_scrolled {
  background: #ffffff;
  position: fixed;
  top: 0;
  height: clamp(5rem, 10vh, 8rem);
  z-index: 1000;
  box-shadow:
    0px 0px 20px 5px rgba(0, 0, 0, 0.1),
    0px 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset 0px 0px 4px 0px rgb(255, 255, 255);
}

.main_scrolled_before {
  background-color: #fcfcfc;
  z-index: 1000;
  height: clamp(5rem, 10vh, 8rem);
  /* max-height: 10vh; */
  box-shadow:
    0px 0px 20px 5px rgba(0, 0, 0, 0.1),
    0px 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset 0px 0px 4px 0px rgb(255, 255, 255);
}

.header_container {
  display: flex;
  min-width: auto;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 950px) {
  .header_container {
    display: flex;
    min-width: 100vw;
    justify-content: space-between;
    align-items: center;
  }
}

.logo_container_after {
  width: clamp(5rem, 20vw, 20rem);
  height: clamp(30px, 5vw, 70px);
}

.container_one {
  display: none;
}

@media screen and (min-width: 951px) {
  .container_one {
    display: block;
    max-width: 30%;
    min-width: auto;
    margin-left: clamp(10px, 1.5vw, 20px);
  }
}

.logo_container_before {
  /* margin-left: -5%; */
  /* margin-top: -3.7vh;
      margin-bottom: -3.7vh; */
  /* min-height: 12.8vh;
      max-height: 12.8vh; */
  /* min-width: 5rem;
      max-width: 15rem; */

  width: clamp(5rem, 20vw, 20rem);
  /* 
      padding: 1vw; */
}

/* nav css */

.container_second {
  z-index: 1000;
  position: relative;
}

.nav_container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 1vh;
}

.whatsap_button {
  display: none;
}

.getStartedModel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

@media screen and (max-width: 982px) {
  .getStartedModel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}

@media screen and (min-width: 1067px) {
  .whatsap_button {
    position: relative;
    display: block;
  }
}

.nav_container_before {
  align-items: center;
  box-shadow:
    0px 0px 20px 5px rgba(0, 0, 0, 0.1),
    0px 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset 0px 0px 4px 0px rgb(255, 255, 255);
  box-shadow: 0 0 0 2px slate-500;
  background: linear-gradient(180deg, #4e3572 0%, #733c64 100%);
  color: #fcfcfcfc;
}

.call_to_action_buttons {
  align-items: center;
  box-shadow:
    0px 0px 20px 5px rgba(0, 0, 0, 0.1),
    0px 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset 0px 0px 4px 0px rgb(255, 255, 255);
  box-shadow: 0 0 0 2px slate-500;
  background: linear-gradient(180deg, #4e3572 0%, #733c64 100%);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 60px;
  color: #fcfcfcfc;
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin: 0px 5px;
  font-size: 0.8rem;
}

/* third container */

.container_third {
  display: none;
}

@media screen and (min-width: 950px) {
  .container_third {
    display: block;
    min-width: 2%;
    max-width: 25%;
    padding-right: 1vw;
    cursor: pointer;
    max-height: 41px;
  }
}

@media screen and (max-width: 950px) {
  .container_one {
    display: block;
    max-width: auto;
    min-width: auto;
  }

  .container_second {
    min-width: auto;
    max-width: auto;
  }
}
